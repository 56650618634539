import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../../components/Layout'
import MetaImage from '../../assets/images/meta/scaling-software-engineering-at-startups.png'
import LeadBlock from '../../components/blocks/LeadBlock'
import VideoBlock from '../../components/blocks/VideoBlock'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'
import CTABlock from '../../components/blocks/CTABlock'
import Box from '../../components/primitives/Box'

export const query = graphql`
  {
    videoThumbnail: file(
      relativePath: {
        eq: "images/scaling-software-engineering-at-startups.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const VideoPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Scaling software engineering at startups [LinkedIn Live]"
      variant="light"
      isNew
      description="Otto and Rebecca discuss scaling engineering teams without losing focus on customer needs. They delve into hiring practices, when to introduce management layers, and how team dynamics affect the engineering organization as the company grows."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <LeadBlock
          heading={
            <>Scaling software engineering at startups [LinkedIn Live]</>
          }
          content="Otto and Rebecca discuss scaling engineering teams without losing focus on customer needs. They delve into hiring practices, when to introduce management layers, and how team dynamics affect the engineering organization as the company grows."
        />
        <VideoBlock
          videoId="xXkAlRZ12HI"
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          thumbnail={getImage(data.videoThumbnail)!}
        />
      </GlobalStyleReset>
      <Box paddingBottom={responsiveScale(92)} />
      <CTABlock
        title={
          <>Start systematically improving developer productivity today.</>
        }
      />
    </Layout>
  )
}

export default VideoPage
